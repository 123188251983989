<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 天气情况 -->
      <div class="content-item weather-conditions">
        <div class="content-title">
          <span>湛江市天气情况</span>
        </div>
        <div class="content-charts">
          <div class="weather-info">
            <span>{{ warnTxt }}</span>
          </div>
          <div class="main-conditions">
            <div class="single-conditions" v-if="nowWeather.icon">
              <div class="icon">
                <img
                  :src="
                    require(`../../../assets/img/large-screen/76px/${nowWeather.icon}.png`)
                  "
                  alt=""
                />
              </div>
              <span class="num">{{ nowWeather.temp }}°C</span>
              <span>{{ nowWeather.text }}</span>
              <span>{{ nowWeather.windDir }} {{ nowWeather.windScale }}级</span>
            </div>
            <div class="some-conditions">
              <div
                v-for="(ele, idx) in thrWeather"
                v-show="idx > 0 && idx < 4"
                :key="idx"
                class="some-item"
              >
                <span>{{ $.formatDateToDayNum(new Date(ele.fxDate)) }}</span>
                <span>{{ $.formatDate(new Date(ele.fxDate), 'MM-dd') }}</span>
                <div class="icon">
                  <img
                    :src="
                      require(`../../../assets/img/large-screen/42px/${ele.iconDay}.png`)
                    "
                    alt=""
                  />
                </div>
                <span class="num">{{ ele.tempMax }}～{{ ele.tempMin }}°C</span>
                <span>{{ ele.textDay }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 水体监测 -->
      <div class="content-item aquatic-monitoring">
        <div class="content-title">
          <span>水体监测</span>
        </div>
        <div class="content-charts">
          <swiper :options="swiperOption">
            <swiper-slide v-for="ele in quotaList" :key="ele.boxId">
              <div class="charts-item" @click="clickIdx(ele)">
                <div class="item-title">{{ ele.boxName }}</div>
                <div class="item-content">
                  <div class="ls-box">
                    <div class="txts">
                      溶氧值
                      <span>{{ ele.oxygen }}mg/L</span>
                    </div>
                    <div class="progress">
                      <el-progress
                        :stroke-width="12"
                        :percentage="ele.oxygen_percent"
                        :show-text="false"
                      ></el-progress>
                    </div>
                  </div>
                  <div class="ls-box">
                    <div class="txts">
                      pH
                      <span>{{ ele.ph }}</span>
                    </div>
                    <div class="progress">
                      <el-progress
                        :stroke-width="12"
                        :percentage="ele.ph_percent"
                        :show-text="false"
                      ></el-progress>
                    </div>
                  </div>
                  <div class="ls-box">
                    <div class="txts">
                      水温
                      <span>{{ ele.water }}°C</span>
                    </div>
                    <div class="progress">
                      <el-progress
                        :stroke-width="12"
                        :percentage="ele.water_percent"
                        :show-text="false"
                      ></el-progress>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 网箱1 -->
      <!-- <div class="content-item box-conditions">
        <div class="content-title">
          <span>{{ boxName }}</span>
        </div>
        <div class="content-charts">
          <div
            id="box-conditions-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div> -->
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 中部分区域 start ------------------------------>
    <div class="content-middle" style="display: none">
      <!-- 年总产值 -->
      <div class="total-num">
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="+infoData.waterMonitorNumber || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水质检测/台</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="2"
              :start-val="0"
              :end-val="+infoData.waterCamera || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水上摄像头/台</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="+infoData.underwaterCamera || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水下摄像头/台</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="+infoData.underwaterRobot || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">水下机器人/台</div>
        </div>
      </div>
    </div>
    <!------------------------------ 中部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 视频监控 -->
      <div class="content-item video-monitoring">
        <div class="content-title">
          <span>视频监控</span>
        </div>
        <div class="content-charts">
          <div
            :class="[
              videoList && videoList.length == 0
                ? 'video-not-found'
                : 'video-box',
            ]"
          >
            <Video
              v-if="showVideo"
              :videoUrl="videoItem && videoItem.ezopenUrl"
              :token="videoItem && videoItem.accessToken"
              :videoCode="videoItem && videoItem.videoCode"
              :videoType="videoItem && videoItem.facilityCodeType"
            ></Video>
            <div class="video-mark" @click="dialogFormVisible = true"></div>
          </div>
          <div class="video-options">
            <div class="top-icon">
              <img
                src="../../../assets/img/large-screen/icon-top-arrow.png"
                alt
                @click="videoSwiper('down')"
              />
            </div>
            <div class="main-ls" ref="video_list">
              <div
                class="main-ls-context"
                :style="`margin-top: ${(curIdx * itemheight) / 5}px`"
              >
                <div
                  v-for="(ele, i) in videoList"
                  :key="i"
                  :class="['ls-item', { active: ele.selected }]"
                  @click="videoView(i)"
                >
                  {{ ele.boxName && ele.boxName.slice(0, 4) }}
                </div>
              </div>
            </div>
            <div class="bottom-icon">
              <img
                src="../../../assets/img/large-screen/icon-bottom-arrow.png"
                alt
                @click="videoSwiper('up')"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 溯源分析 -->
      <div class="content-item traceability-analysis">
        <div class="content-title">
          <span>{{ boxName }}</span>
        </div>
        <div class="content-charts">
          <div
            id="box-conditions-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>
    <el-dialog
      title="视频监控"
      :width="'70%' || app.fontHandle(1600, 'px')"
      height="80vh"
      top="20vh"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
    >
      <div class="content-charts">
        <div
          :class="[
            videoList && videoList.length == 0
              ? 'video-not-found'
              : 'video-box',
          ]"
          v-loading="!showVideo"
          element-loading-background="transparent"
        >
          <VideoPpopup
            :videoUrl="videoItem && videoItem.ezopenUrl"
            :token="videoItem && videoItem.accessToken"
            :videoCode="videoItem && videoItem.videoCode"
            :videoType="videoItem && videoItem.facilityCodeType"
          ></VideoPpopup>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Vue from 'vue'
import { Progress } from 'element-ui'
Vue.use(Progress)

import { getWeather, getWarning } from '../modules/weather-api'
import CountFlop from '../../../components/count-to-component.vue'

import * as $echarts from 'echarts'
import CountTo from 'vue-count-to'
import Video from './video.vue'
import moment from 'moment'
import VideoPpopup from './video-popup.vue'
export default {
  name: 'digital-park',
  components: {
    // videoDemo,
    CountFlop,
    CountTo,
    swiper,
    swiperSlide,
    Video,
    VideoPpopup
  },
  inject: ['app'],
  data() {
    return {
      videoList: null, // 视频列表
      videoUrl: null, // 视频路径
      videoItem: null, // 视频对象
      showVideo: true, // 是否展示视频
      videoSwiperCount: 0, // 视频上下点击滑动计数
      itemheight: 0,
      curIdx: 0,
      dialogFormVisible: false,
      infoData: { issueNumberList: [], queryCountByYearList: [] },
      boxName: '', // 当前显示的下标
      swiperOption: {
        mousewheel: {
          thresholdTime: 200,
        },
        // 所有的参数同 swiper 官方 api 参数
        spaceBetween: this.app.fontHandle(30) - 1,
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 1000, //1秒切换一次
        // },
        // mousewheel: true,
        slidesPerView: 5,
        // loop: true,
        direction: 'vertical',
      },
      nowWeather: {}, // 实时天气
      thrWeather: [], // 三天天气
      warnTxt: '',
      params: {},
      map: null,
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm'),
      cityList: [],
      active: -1,
      conn: true,
      quotaList: [], // (水体监测)指标列表
      // 网箱1
      conditions: {
        idName: 'box-conditions-chart',
        legend: {
          data: ['溶氧', 'pH', '水温'],
        },
        series: {
          // 水温
          waterData: {
            name: '水温',
            data: [
              // 22.92, 22.92, 22.92, 22.92, 22.91, 22.92, 22.92, 22.91, 22.91,
              // 22.92, 22.91, 22.91, 22.91, 22.91, 22.92, 22.91, 22.93, 22.92,
              // 22.94, 22.93, 22.94, 22.94, 22.95, 22.94, 22.95, 22.95, 22.95,
              // 22.95, 22.96, 22.95, 22.97, 22.96, 22.97, 22.97, 22.98, 22.97,
              // 22.98, 22.98, 22.98, 22.98, 22.99, 22.98, 22.98, 22.99, 22.98,
              // 22.98, 22.98, 22.98, 22.98, 22.98, 22.97, 22.98, 22.97, 22.97,
              // 22.96, 22.97, 22.96, 22.96, 22.96, 22.96, 22.96, 22.96, 22.96,
              // 22.96, 22.96, 22.96, 22.96, 22.96, 22.97, 22.96, 22.96, 22.97,
              // 22.97, 22.96, 22.97, 22.97, 22.97, 22.97, 22.98, 22.97, 22.98,
              // 22.98, 22.98, 22.98, 22.99, 22.98, 22.99, 22.99, 23, 22.99, 23,
              // 23, 23,
            ],
          },
          // 溶氧
          oxData: {
            name: '溶氧',
            data: [
              // 7.48, 7.48, 7.49, 7.48, 7.49, 7.49, 7.5, 7.49, 7.53, 7.5, 7.53,
              // 7.53, 7.56, 7.53, 7.58, 7.56, 7.59, 7.58, 7.63, 7.59, 7.59, 7.63,
              // 7.59, 7.59, 7.6, 7.59, 7.62, 7.6, 7.64, 7.62, 7.63, 7.64, 7.67,
              // 7.63, 7.67, 7.67, 7.67, 7.67, 7.67, 7.67, 7.7, 7.67, 7.69, 7.7,
              // 7.75, 7.69, 7.75, 7.75, 7.76, 7.75, 7.79, 7.76, 7.83, 7.79, 7.88,
              // 7.83, 7.91, 7.88, 7.96, 7.91, 7.96, 7.96, 7.97, 7.96, 7.99, 7.97,
              // 7.99, 7.99, 8.02, 7.99, 8.03, 8.02, 8.02, 8.03, 8.05, 8.02, 8.04,
              // 8.05, 8.04, 8.04, 8.09, 8.09, 8.08, 8.09, 8.08, 8.08, 8.09, 8.08,
              // 8.09, 8.09, 8.09, 8.09,
            ],
          },
          // ph
          phData: {
            name: 'pH',
            data: [
              // 8.08,
              // 8.08,
              // 8.08,
              // 8.08,
              // 8.08,
              // 8.08,
              // 8.09,
              // 8.08,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.08,
              // 8.09,
              // 8.09,
              // 8.08,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.09,
              // 8.1,
              // 8.09,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.1,
              // 8.11,
              // 8.1,
              // 8.12,
              // 8.11,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.11,
              // 8.12,
              // 8.12,
              // 8.11,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // '',
              // '',
              // '',
              // '',
              // '',
              // '',
              // '',
              // 8.13,
              // 8.13,
              // 8.12,
              // 8.13,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
              // 8.12,
            ],
          },
        },
        xAxis: {
          data: [
            // '11:05',
            // '11:06',
            // '11:07',
            // '11:08',
            // '11:09',
            // '11:10',
            // '11:11',
            // '11:12',
            // '11:13',
            // '11:14',
            // '11:15',
            // '11:16',
            // '11:17',
            // '11:18',
            // '11:19',
            // '11:20',
            // '11:21',
            // '11:22',
            // '11:23',
            // '11:24',
            // '11:25',
            // '11:26',
            // '11:27',
            // '11:28',
            // '11:29',
            // '11:30',
            // '11:31',
            // '11:32',
            // '11:33',
            // '11:34',
            // '11:35',
            // '11:36',
            // '11:37',
            // '11:38',
            // '11:39',
            // '11:40',
            // '11:41',
            // '11:42',
            // '11:43',
            // '11:44',
            // '11:45',
            // '11:46',
            // '11:47',
            // '11:48',
            // '11:49',
            // '11:50',
            // '11:51',
            // '11:52',
            // '11:53',
            // '11:54',
            // '11:55',
            // '11:56',
            // '11:57',
            // '11:58',
            // '11:59',
            // '12:00',
            // '12:01',
            // '12:02',
            // '12:03',
            // '12:04',
            // '12:05',
            // '12:06',
            // '12:07',
            // '12:08',
            // '12:09',
            // '12:10',
            // '12:11',
            // '12:12',
            // '12:13',
            // '12:14',
            // '12:15',
            // '12:16',
            // '12:17',
            // '12:18',
            // '12:19',
            // '12:20',
            // '12:21',
            // '12:22',
            // '12:23',
            // '12:24',
            // '12:25',
            // '12:26',
            // '12:27',
            // '12:28',
            // '12:29',
            // '12:30',
            // '12:31',
            // '12:32',
            // '12:33',
          ],
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            showMaxLabel: true,
            rotate: 45,
            interval: null,
          },
        },
        yAxis: {},
      },
      // 月查询次数
      traceability: {
        idName: 'traceability-analysis-search',
        title: {
          text: '月查询次数',
        },
        xAxis: {
          data: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
        },
        series: {
          name: '次数',
          data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30],
        },
      },
      // 月发放溯源码数量
      traceabilitySend: {
        idName: 'traceability-analysis-send',
        title: {
          text: '月发放溯源码数量',
        },
        xAxis: {
          data: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
        },
        series: {
          name: '数量',
          data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30],
        },
      },
      videoList: null, // 视频列表
      videoUrl: null, // 视频路径
      showVideo: true, // 是否展示视频
      videoSwiperCount: 0, // 视频上下点击滑动计数
    }
  },
  computed: {},
  methods: {
    clickIdx(box) {
      this.boxName = box.boxName
      // 水体监测详情
      this.$axios(
        `/zhanjiang-dataview/viewDigitalPark/queryWaterInfoByBoxId/${box.boxId}`
      )
        .then((res) => {
          // 有数据
          if (res.data && res.data.length > 0) {
            const conditions = JSON.parse(JSON.stringify(this.conditions))
            // 获取当前时间
            const nowTime = moment().format('YYYY-MM-DD HH:mm')
            // 指标设置
            // 获取一个过去几小时数据都为''的数组
            const dataArr = this.createEmptyDataArrByHour(nowTime, 24)
            // 遍历返回数据中的list第一项
            res.data &&
              res.data.forEach((re) => {
                const res_date = (re.updateTime = re.updateTime.slice(5, 16))
                dataArr.forEach((res2) => {
                  const res2_date = res2.updateTime.slice(5, 16)
                  if (res_date === res2_date) {
                    res2['isData'] = true
                    res2.oxygen = re.oxygen
                    res2.ph = re.ph
                    res2.water = re.water
                  }
                })
              })
            // 遍历数据，对为''的数据做一下处理
            // 如果它前3项不为''，那么赋值于它
            dataArr.forEach((re, index) => {
              if (re.oxygen === '') {
                for (let i = index - 1; i >= index - 3; i--) {
                  if (
                    dataArr[i] &&
                    dataArr[i].oxygen !== '' &&
                    dataArr[i].isData
                  ) {
                    re.oxygen = dataArr[i].oxygen
                    re.ph = dataArr[i].ph
                    re.water = dataArr[i].water
                  }
                }
              }
            })
            conditions.series.waterData.data,
              conditions.series.oxData.data,
              conditions.series.phData.data,
              (conditions.xAxis.data = [])

            dataArr.forEach((re) => {
              conditions.series.waterData.data.push(re.water)
              conditions.series.oxData.data.push(re.oxygen)
              conditions.series.phData.data.push(re.ph)
              conditions.xAxis.data.push(re.updateTime.slice(11, 16))
            })
            conditions.xAxis.axisLabel.interval = dataArr.length / 8 - 1
            this.seedlingBreedingChart(conditions)
          } else {
            // 无数据, 使用默认参数
            this.seedlingBreedingChart(this.conditions)
          }
        })
        .catch(() => {})
    },
    // 项目概况 圆饼图
    // 折线图
    seedlingBreedingChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(16, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: obj.series.waterData.name,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#F06CFA',
            },
            data: obj.series.waterData.data,
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 100, 235, 0.3)',
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255, 100, 235, 0.24)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.phData.name,
            data: obj.series.phData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3CB8FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#3CB8FF',
            },
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(60, 184, 255, 0.35)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.oxData.name,
            data: obj.series.oxData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#9287FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#9287FF',
            },
            smooth: true,
            symbolSize: 0,
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图
    seedlingBreedingChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(16, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: obj.series.waterData.name,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#F06CFA',
            },
            data: obj.series.waterData.data,
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 100, 235, 0.3)',
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255, 100, 235, 0.24)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.phData.name,
            data: obj.series.phData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3CB8FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#3CB8FF',
            },
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(60, 184, 255, 0.35)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.oxData.name,
            data: obj.series.oxData.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#9287FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#9287FF',
            },
            smooth: true,
            symbolSize: 0,
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图2
    traceabilityAnalysisChart(obj) {
      let fontHandle = this.app.fontHandle
      let LinearGradient =
        obj.idName === 'traceability-analysis-search'
          ? [
              {
                offset: 0,
                color: 'rgba(60, 184, 255, 0.35)',
              },
              {
                offset: 1,
                color: 'rgba(255, 255, 255, 0)',
              },
            ]
          : null

      let serieItem = {
        name: obj.series.name,
        type: 'line',
        itemStyle: {
          normal: {
            color: '#F553FB',
          },
        },
        lineStyle: {
          width: 2.5,
          color: '#F06CFA',
        },
        data: obj.series.data,
        smooth: true,
        symbolSize: 0,

        areaStyle: {
          //区域填充样式
          normal: {
            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: new $echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              LinearGradient || [
                {
                  offset: 0,
                  color: 'rgba(255, 100, 235, 0.3)',
                },
                {
                  offset: 0.5,
                  color: 'rgba(255, 100, 235, 0.24)',
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)',
                },
              ],
              false
            ),
          },
        },
      }

      if (obj.idName === 'traceability-analysis-search') {
        serieItem.lineStyle.color = serieItem.itemStyle.normal.color = '#3CB8FF'
      }

      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        title: {
          text: obj.title.text,
          textStyle: {
            align: 'center',
            color: '#fff',
            fontSize: fontHandle(20),
          },
          top: 'top',
          left: fontHandle(12, 'px'),
        },
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: ['次数', '数量'],
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(46, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [serieItem],
      }
      chart.setOption(option)
    },
    // 选择视频展示
    videoView(i) {
      this.showVideo = false
      this.videoList.forEach((re, j) => {
        re.selected = j == i ? (true, (this.videoItem = re)) : false
      })
      setTimeout(() => {
        this.showVideo = true
      }, 0)
    },
    // 视频列表上下滑动
    videoSwiper(btn) {
      this.itemheight = this.$refs.video_list.clientHeight // 列表元素高
      let curIdx = this.curIdx
      // 往上滑动
      if (btn == 'up') {
        curIdx--
      } else {
        curIdx++
      }
      console.log(this.videoList.length, this.curIdx)
      if (
        this.videoList.length <= 5 ||
        !(curIdx >= -this.videoList.length + 5 && curIdx <= 0)
      ) {
        return
      }
      this.curIdx = curIdx
    },
    /**
     * @brief: 生成过去24h/48h/72h的空数据数组
     * @author: Scarlett Xiao
     * @Date: 2021-10-19 17:57:17
     * @param {*} nowTime 当前时间(YYYY-MM-DD HH:mm)
     * @param {number} hour 多少小时(24)
     */
    createEmptyDataArrByHour(nowTime, hour) {
      const count = hour * 60 // 过去多少小时乘60分钟
      const DATA = []
      DATA.push({
        updateTime: nowTime,
        oxygen: '',
        ph: '',
        water: '',
        isData: false,
      })
      while (DATA.length < count) {
        const date = new Date(DATA[0].updateTime.replace(/-/g, '/'))
        // 获取前一分钟的时间
        const prevMinutes = moment(date.getTime())
          .subtract(1, 'minutes')
          .format('YYYY-MM-DD HH:mm')
        DATA.unshift({
          updateTime: prevMinutes,
          oxygen: '',
          ph: '',
          water: '',
          isData: false,
        })
      }
      return DATA
    },
  },
  mounted() {
    // 模板编译/挂载之后
    this.$axios('/zhanjiang-dataview/viewDigitalPark/queryInfo')
      .then((res) => {
        this.infoData = res.data
        this.traceabilitySend.xAxis.data = res.data.issueNumberList.map(
          (ele) => ele.name
        )
        this.traceabilitySend.series.data = res.data.issueNumberList.map(
          (ele) => ele.value
        )
        this.traceability.xAxis.data = res.data.queryCountByYearList.map(
          (ele) => ele.name
        )
        this.traceability.series.data = res.data.queryCountByYearList.map(
          (ele) => ele.value
        )
        this.traceabilityAnalysisChart(this.traceabilitySend)
        this.traceabilityAnalysisChart(this.traceability)
      })
      .catch(() => {})

    // 获取视频列表信息
    this.$axios({
      method: 'get',
      url: '/zhanjiang-dataview/viewDigitalPark/queryVideoList',
      data: null,
    })
      .then((res) => {
        if (res.code === '00000') {
          if (res.data instanceof Array) {
            res.data.forEach((re, i) => {
              re.selected = i == 0 ? true : false
            })
          }
          this.videoList = res.data || []
          // 初始化视频
          this.videoView(0)
        }
      })
      .catch(() => {})

    // 水体监测
    this.$axios('/zhanjiang-dataview/viewDigitalPark/queryWaterDataList')
      .then((res) => {
        // 上下线
        function calcPercent(val, quota) {
          let max = { oxygen: 30, ph: 14, water: 60 }[quota] // 获取指标的上限
          return (100 * (val / max)).toFixed(2)
        }
        // 映射
        this.quotaList = res.data.map(
          (re) =>
            new Object({
              boxId: re.boxId,
              boxName: re.boxName,
              oxygen: re.oxygen,
              ph: re.ph,
              water: re.water,
              oxygen_percent: +calcPercent(re.oxygen, 'oxygen'),
              ph_percent: +calcPercent(re.ph, 'ph'),
              water_percent: +calcPercent(re.water, 'water'),
            })
        )

        // 初始化网箱详情
        this.clickIdx(this.quotaList[0])
      })
      .catch(() => {})
  },
  created() {
    // 获取湛江预警信息
    // this.$axios({
    //   method: 'get',
    //   url: '/zhanjiang-dataview/viewDigitalPark/queryWeatherInfo',
    //   data: { location: 101281001 },
    // })
    //   .then((res) => {
    //     if (res.data.code === '00000') {
    //       console.log('data', res.data)
    //     }
    //   })
    //   .catch(() => {})
    getWarning().then((res) => {
      // console.log('getWarning', res)
      this.warnTxt = res.warning.length > 0 ? res.warning[0].text : ''
    })
    getWeather('now', '113.530669,22.929231').then(
      (res) => {
        // console.log('nowWeather', res.now)
        this.nowWeather = res.now
      },
      (e) => {
        console.error('LocHandle error:' + e)
      }
    )
    getWeather('7d', '113.530669,22.929231').then(
      (res) => {
        // console.log('thrWeather', res.daily)
        this.thrWeather = res.daily
      },
      (e) => {
        console.error('LocHandle error:' + e)
      }
    )
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
}
</script>
<style lang="less" scoped>
@import './digital-park.less';











</style>
